@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

*{padding: 0px; margin: 0px;}

.theme-lg {

    background-color: #f0ece4;
    
    //navbar
    --bg-navbar: #f0ece4;
    --border-navbar: #cbc8c2;

    --color-dark: #0c0c0c;
    --color-primary:  #ea1917;
    --color-secondary: #fff;
    --color-light: #ffff;

    // button
    --btn-dark: var(--color-dark);
    --btn-dark-text: #fff;

    --btn-primary: var(--color-primary);
    --btn-primary-text: #fff;

    --btn-secondary: var(--color-secondary);
    --btn-secondary-border: #646464;
    --btn-secondary-text: #000;

    --btn-light: var(--color-light);
    --btn-light-border: #646464;
    --btn-light-text: #000;

    // header call to action
    --header-bg-overlay: linear-gradient(180deg, rgb(61 61 61 / 15%) 0%, rgb(240 236 228) 100%);
    --header-color-text-small: #212121;
    --header-call-to-action: #e6e1d6;
    --header-call-to-action-text: #000;

    // footer
    --bg-footer-cta: #e6e1d6;
    --color-footer-cta: #7d7d7d;

    // Others

    --featured-badge: var(--color-primary);
    --featured-border: var(--color-primary);
    --featured-text: var(--color-primary);

    --middle-support-fulltime-bg: var(--bg-navbar);
    --middle-support-fulltime-text: #393535;

    --middle-call-to-action-bg-overlay: linear-gradient(180deg, rgb(240 236 228) 0%, rgb(14 14 14 / 74%) 100%);

    --color-text-field: var(--color-primary);
}
